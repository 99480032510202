const API_URL = 'https://api.themoviedb.org/3/'
const API_KEY = '5d4558fe22a05a7916ff179b7fe88bcb'

const IMAGE_BASE_URL = 'https://image.tmdb.org/t/p/'

//Sizes: w300, w780, w1280, original
const BACKDROP_SIZE = 'w1280'

// w92, w154, w185, w342, w500, w780, original
const POSTER_SIZE = 'w500'

export { API_URL, API_KEY, IMAGE_BASE_URL, BACKDROP_SIZE, POSTER_SIZE }
